@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
  font-family: 'Roboto', sans-serif;
}
.numberInput{
  border: none;
  outline: none;
  text-align: center;
}
.numberInput::-webkit-inner-spin-button, 
.numberInput::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.tableBottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.tableBottom .totalCalculation{
  min-width: 250px;
}